@use "~styles/colors";
@use "~styles/spacing";

.patient-chooser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 350px;
  height: 60px;
}

.patient-dropdown-wrapper {
  min-width: 250px;
  margin-bottom: -1.5em;
  height: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;

  @media (max-width: spacing.$viewport7) {
    min-width: 150px;
  }
}

.patient-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 9px;
  width: 2em;
  height: 2em;
}
