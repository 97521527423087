@use "~styles/colors";
@use "~styles/spacing";

.single-patient-top-bar {
  border-bottom: 1px solid colors.$border-light;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline-start: -2em;
  margin-inline-end: -2em;
  margin-top: -0.5em;
  margin-bottom: 1em;
  padding: 0.5em 2em;

  @media (max-width: spacing.$viewport7) {
    margin-inline-start: -(spacing.$app-content-vertical-padding-mobile);
    margin-inline-end: -(spacing.$app-content-vertical-padding-mobile);
    margin-top: -(spacing.$app-content-horizontal-padding-mobile);
  }
}

.single-patient__back {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    width: 1em;
    height: 1em;
    margin-inline-end: 0.5em;

    path {
      fill: colors.$gold;
    }
  }

  h3 {
    margin: 0;
    font-weight: 300;
  }
}

.single-patient-top-area {
  margin-bottom: 1.5em;
  border-bottom: 1px solid colors.$border-light;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.single-patient-top-left {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  height: 100%;
  justify-content: space-between;
}

.single-patient-top-bar__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider_wrapper {
  margin-top: 20px;
  margin-left: 11px;
}

.library-page-section {
  .collection_wrapper,
  .resource-wrapper,
  .file-card {
    margin: 28px 11px 0 11px;
  }
}
