@use "~styles/colors.scss";

.create-pin {
  padding: 0 80px 20px 0;
}
.create-pin_description {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: colors.$davys-grey;
}

.create-pin_input-cover {
  position: relative;
  margin-top: 36px;
  input::placeholder {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.56);
  }
}

.create-pin_check-icon {
  position: absolute;
  content: "";
  bottom: 0;
  right: 14px;
  transform: translateY(-6px);
  svg {
    width: 22px;
    height: 22px;
    fill: #47ba5e;
  }
}
