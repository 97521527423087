@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";

.input-search {
  position: relative;
  width: 284px;

  .input__label {
    display: none;
  }
  
  .input {
    margin-bottom: 0;
  }
  
  .input-search__input {
    padding: 10px 16px 11px 42px;
    border-radius: 2px;
    transition: animations.$baseTransition;
  }

  svg {
    position: absolute;
    width: 14px;
    height: 16px;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    fill: colors.$light-blue;
  }

  @media (max-width: spacing.$viewport7) {
    width: 100%;
  }
}