@use "~styles/colors";
@use "~styles/spacing";
@use "~styles/typography";

.patient-workbook-panel-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.workbook-page {

  .side-panel__header.flex {
    align-items: center;
  }

  .assignment-icon {
    margin-top: 0.25em;

    @media screen and (max-width: spacing.$viewport7) {
      margin-top: 0.5em;
    }       
  }

  .assignment-card {
    padding: 12px 5px 2px 16px;
    @media screen and (max-width: spacing.$viewport7) {
      padding: 1em;
    }   

    &_title {
      align-items: flex-start;  
    }

    &_right {
      @media screen and (max-width: spacing.$viewport7) {
        align-items: flex-start;
      }    
    }

    &_buttons {
      @media screen and (max-width: spacing.$viewport7) {
        margin-left: -48px; 
      }   
    }

    &_btn {
      @media screen and (max-width: spacing.$viewport7) {
        font-size: 0.7em;
      }   
    }
  }  
}

.side-panel_title {
  font-weight: 400;
  font-size: 26px;
  line-height: 1.2;
  color: colors.$brand-primary;
}
