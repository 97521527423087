@use "~styles/colors";

.score-card {
  border: 1px solid colors.$border-light;
  display: flex;
  padding-top: .75em;
  padding-right: .75em;
  padding-bottom: .25em;
  padding-left: 1em;
  border-radius: 6px;
  margin-bottom: 1em;
  gap: 1.25em;

  .score-card__score {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 4em;
    padding-right: 1em;

    .score-card__scoreValue {
      margin: 0;
      font-size: 2em;
    }

    .score-card__scoreLabel {
      text-transform: uppercase;
      font-size: .75em;
    }
  }

  .score-card__instructions {
    flex: 1;

    .score-card__instructionsLabel {
      font-weight: bold;
      margin-bottom: .5em;
      display: block;
    }
  }

  .score-card__divider {
    width: 1px;
    background-color: colors.$border-light;
    margin: 0;
    align-self: stretch;
  }

  .score-card__fieldsets {
    padding: 0 .5em;

    .score-card__fieldsetsLabel {
      font-weight: bold;
      margin-bottom: .5em;
      display: block;
    }

    .score-card__fieldsetsList {
      list-style: none;
      padding: 0;
      margin: 0;

      .score-card__fieldsetItem {
        margin-bottom: .25em;
        font-size: 0.9em;
      }
    }
  }
}