.gauge {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -15px;
  position: relative;

  &__canvas {
    border-radius: 50%;
    background: #222;
    animation: fadeIn 0.5s ease-in-out;
  }

  .gauge-content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    height: 90px;
  }

  .gauge-label {
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    color: #757575;
  }

  .gauge-min-max-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 20px;
  }

  .gauge-score {
    font-size: 1.6em;
    text-align: center;
  }

  .gauge-scoring-type {
    font-size: .8em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1em;
  }

  .gauge-min-label {
    text-align: left;
    font-size: 0.8em;
    color: #757575;
  }
  
  .gauge-max-label {
    text-align: right;
    font-size: 0.8em;
    color: #757575;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} 