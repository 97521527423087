@use "~styles/spacing";
@use "~styles/colors";

.settings-page {
  .settings-section {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
  }

  .settings-section__content {
    max-width: 400px;
  }

  .section-title {
    margin-bottom: 0;
  }

  .settings-section__avatar-section {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 90px;
    margin: 0 auto;
    border: 1px dashed;
    border-radius: 50%;
    height: 90px;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;

    .settings-section__avatar-edit-icon {
      position: absolute;
      right: 10px;
      bottom: 15px;
      background: black;
      padding: 0.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
        width: 0.75em;
        height: 0.75em;
        path {
          fill: white;
        }
      }
    }
  }

  .settings-section__avatar-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .settings-section__toggle-box {
    margin-bottom: 30px;
    p {
      color: colors.$text-grey100;
    }
  }

  .divider {
    width: 100%;
    background-color: colors.$border-dark;
    border: none;
    height: 1px;
    margin-bottom: 1.5em;
  }

  @media (max-width: spacing.$viewport4) {
    padding-top: 1em;
  }
}
