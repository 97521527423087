@use "~styles/colors";
@use "~styles/spacing";

.patient-info-card {
  background: white;
  box-shadow: colors.$boxshadow-light;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.5em 1em;
  border-top: 3px solid colors.$brand-primary-accent500;
  margin-bottom: 1em;

  @media (max-width: spacing.$viewport7) {
    width: 100%;
  }

  .patient-info-card__left-side {
    margin-inline-end: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: colors.$border-light;
    padding: 0.5em;
    border-radius: 0.5em 0 0 0.5em;
  }

  .patient-info-card__patient_info {
    table {
      border-collapse: collapse;
    }

    .patient-info-table-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid colors.$border-light;

      td {
        padding: 0.5em 0;
      }

      &:last-child {
        border-bottom: none;
      }
    }
    .patient-info-table-label {
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-inline-end: 1em;

      svg {
        path {
          fill: colors.$brand-primary-accent;
        }
      }
    }
  }
}
