@use "~styles/colors";
@use "~styles/animations";
@use "~styles/spacing";
@use "~styles/typography";

.assignment-card {
  padding: 1em;
  border: 3px solid transparent;
  margin: 0.5em 0em;
  border-radius: 4px;
  box-shadow: colors.$boxshadow-subtle;
  transition: 0.25s;
  position: relative;
  background: white;

  .assignment-card_action-menu {
    width: 1em;
    height: 1em;
    margin-inline-start: auto;
    display: none;
    position: relative;

    svg {
      path {
        fill: colors.$brand-primary;
      }
    }
  }

  &:hover,
  &.assignment-card__active {
    box-shadow: colors.$boxshadow-light;
    border-color: colors.$brand-primary100;

    .assignment-card_action-menu {
      display: block;
    }
  }

  &_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: spacing.$viewport7) {
      flex-direction: column;
    }
  }

  &_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &_label {
    line-height: 1.5em;
  }

  &_last-subm {
    font-weight: 400;
    font-size: 12px;
    line-height: 2;
    color: colors.$davys-grey;
  }
}

.assignment-card_title {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.assignment-card_subtitle {
  font-size: 0.8em;
  color: colors.$text-grey;
}

.assignment-card__expired {
  opacity: 0.4;
}

.assignment-card_buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 0.8125em;
}

.assignment-card_subtitle {
  height: 1.5em;
  font-size: 0.8em;
  color: colors.$text-grey;
  margin-right: 0.875em;
}

.assignment-card__expired {
  opacity: 0.4;
}

.assignment-card__simplified {
  .assignment-card_container {
    align-items: center;
  }
  .assignment-card_subtitle,
  .assignment-card_buttons {
    margin-top: 0;
  }
}

.assignment-card_btns {
  width: 48px;
  svg {
    width: 16px !important;
    height: 16px;
  }
  .button__icon {
    display: flex;
    align-items: flex-end;
    margin-inline-end: 0 !important;
  }
  @media screen and (min-width: spacing.$viewport9) {
    display: none !important;
  }
}

.extra_btns {
  @media screen and (max-width: spacing.$viewport9) {
    position: absolute;
    bottom: 5px;
    right: -30px;
    transform: translateY(100%);
    border-radius: 6px;
    background: white;
    box-shadow: colors.$boxshadow-subtle;
    z-index: 9;
  }
  @media screen and (min-width: spacing.$viewport9) {
    display: flex;
  }
  &--hidden {
    @media screen and (max-width: spacing.$viewport9) {
      display: none !important;
    }
  }
  &--visible:not(:last-child) {
    @media screen and (max-width: spacing.$viewport9) {
      display: block;
      padding: 10px 24px !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
    }
  }
  &--visible:last-child {
    @media screen and (max-width: spacing.$viewport9) {
      display: block;
      padding: 10px 24px !important;
    }
  }
}
