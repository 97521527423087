@use "~styles/colors";
@use "~styles/typography";
@use "~styles/spacing";

.fill_title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: colors.$davys-grey;

  &.mb {
    margin-bottom: 30px;
  }
}

.fill_label {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  color: colors.$davys-grey;
}

.mb {
  margin-bottom: 12px;
}

.fill_hint {
  margin-right: 130px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  color: colors.$text-grey100;
}

.fill_settings_hint {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42;
  color: colors.$davys-grey;
  button {
    display: inline-block;
    padding: 0;
    color: colors.$carolina-blue;
    background: transparent !important;
    border: 1px solid transparent;
    border-bottom: 1px solid colors.$carolina-blue;
    text-transform: none;
  }
}

.fill_toggle-container {
  margin-bottom: 24px;
  &.disabled {
    opacity: 0.35;
    pointer-events: none;
  }
}

.select {
  margin-bottom: 12px;
}

// Fill-in Assignment modal
.bottom-modal_button-back {
  display: none;
}

.bottom-modal_title {
  margin-left: 42px;
}

.fill-modal_container {
  @media screen and (max-width: spacing.$viewport7) {
    display: block;
    padding: 15px;
  }
  display: flex;
  min-height: 100vh;
  padding: 20px 48px;
  background-color: #f1f1f1;
}

.fill-modal_info {
  @media screen and (max-width: spacing.$viewport7) {
    width: auto;
  }
  width: 340px;

  &_practice-logo {
    margin-bottom: 35px;
  }

  &_section {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  &_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 48px;
    margin-right: 10px;
  }

  &_avatar {
    width: 42px;
    height: 42px;
  }

  &_icon {
    display: flex;
    align-items: center;
    width: 22px;
    height: 22px;
    fill: colors.$indigo-dye;
  }

  &_name {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: #313131;
  }

  &_email {
    font-size: 14px;
    font-weight: 700;
    color: colors.$carolina-blue;
    letter-spacing: 0.15px;
  }

  &_text {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #313131;
  }

  &_brand {
    @media screen and (max-width: spacing.$viewport7) {
      visibility: hidden;
    }
    margin: 25px 0 0 3px;
    font-family: typography.$font-logo-bold;
    font-size: 22px;
    color: colors.$peerple;
  }
}

.fill-modal_form {
  @media screen and (max-width: spacing.$viewport7) {
    width: auto;
    padding: 0 15px 90px 15px;
  }
  width: 455px;
  padding: 0 35px 90px 35px;

  &_description {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  &_buttons {
    @media screen and (max-width: spacing.$viewport7) {
      width: Calc(100% - 60px);
    }
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 20px 0;
    background-color: #f1f1f1;
    width: 455px;
    &::before {
      position: absolute;
      content: "";
      top: -14px;
      left: 0;
      width: 100%;
      height: 14px;
      background: linear-gradient(
        to top,
        rgba(217, 217, 217, 0.3),
        rgb(241, 241, 241, 0.3)
      );
    }
    .button {
      margin: 0 24px 0 0;
    }
    .submit-button {
      @media screen and (max-width: spacing.$viewport4) {
        width: 150px !important;
      }
    }
  }

  &_arrow {
    width: 44px;
    height: 44px;
    .button__icon {
      width: 16px !important;
      height: 15px !important;
      margin-inline-end: 0;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &.button {
      padding: 0;
    }
  }

  &_field {
    position: relative;
    margin-bottom: 20px;
  }
}
