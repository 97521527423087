@use "~styles/colors";
@use "~styles/animations";

.patient_action-menu-wrapper {
  position: relative;
  margin-inline-start: 1em;
}

.patient_action-menu {
  width: 1em;
  height: 1em;
  cursor: pointer;

  svg {
      width: 1em;
      height: 1em;
    path {
      fill: colors.$brand-primary-accent300;
    }
  }
}

.patient_action-menu-dropdown {
  position: absolute;
  background: white;
  box-shadow: colors.$boxshadow-light;
  border-radius: 0px 0px 6px 6px;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  top: 1em;
  inset-inline-end: 0em;
  border: 1px solid colors.$border-light;
  padding: 1em 1em 0.5em 1em;
  height: 0em;
  visibility: hidden;
  opacity: 0;
  transition: animations.$baseTransition;
  z-index: 1;

  &.patient_action-menu-dropdown-visible {
    height: 2em;
    visibility: visible;
    opacity: 1;
    transition: animations.$baseTransition;
  }
}

.patient_action-menu-dropdown-menu {
  margin: 0;
  padding-inline-start: 0;
  list-style: none;
}

.patient_action-menu-dropdown-menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0.5em 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid colors.$border-light;
    margin-bottom: 0.5em;
  }

  svg {
    width: 1em;
    height: 1em;
    margin-inline-end: 0.5em;

    path {
      fill: colors.$brand-primary-accent300;
    }
  }

  &.patient_action-menu-dropdown-menu-item-delete-item {
    svg {
      path {
        fill: colors.$color-danger;
      }
    }
  }
}
